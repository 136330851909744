export default {
  title: "SAK Login Portal",
  copyright: "St.Gallisch-Appenzellische Kraftwerke AG",
  copyrightStart: 2023,
  favicon: process.env.BASE_URL + "sak-favicon.ico",
  logo: require("@/assets/sak-logo-yellow.svg"),
  msgChangePermissionGroup: "loginsakagch_admin_access",
  frontendUrl: "https://login.sak-digital.ch",
  backendUrl: "https://backend.login.sak-digital.ch/api/",
  backgroundImage: require("@/assets/sak-background.png"),
  loginRedirectUrl: "https://accounts.sak.ch/authz-srv/authz",
  passwordChangeUrl: "https://accounts.sak.ch/sak-user-profile/change_password",
  mfaUrl: "https://accounts.sak.ch/sak-user-profile/mfa",
  cidaasClientId: "62fe00f2-e6d4-41a8-a70b-b329a1370a5a",
  billingTypes: [
    {
      id: 0,
      title_de: "ESR",
      title_en: "Payment slip by post",
      visible: true,
      disabled: false,
    },
    {
      id: 4,
      title_de: "Email",
      title_en: "Email",
      visible: true,
      disabled: false,
    },
    {
      id: 6,
      title_de: "ESR und Mail",
      title_en: "Payment slip and email",
      visible: true,
      disabled: false,
    },
    {
      id: 9,
      title_de: "LSV+, Bestätigung per Brief",
      title_en: "LSV+, confirmation by post",
      visible: true,
      disabled: false,
    },
    {
      id: 10,
      title_de: "LSV+, Bestätigung per E-Mail",
      title_en: "LSV+, confirmation by email",
      visible: true,
      disabled: false,
    },
    {
      id: 11,
      title_de: "E-Rechnung/Paynet",
      title_en: "eBill/Paynet",
      note_de: "im E-Banking unter E-Bill Rechnungssteller SAK hinzufügen",
      note_en: "add biller SAK in e-banking under e-bill",
      visible: true,
      disabled: false,
    },
    {
      id: 12,
      title_de: "DebitDirect, Bestätigung per Brief",
      title_en: "DebitDirect, confirmation by post",
      visible: false,
      disabled: false,
    },
    {
      id: 13,
      title_de: "DebitDirect per E-Mail",
      title_en: "DebitDirect direct by email",
      visible: true,
      disabled: false,
    },
  ],
};
